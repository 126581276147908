@import '~@shopify/polaris/build/esm/styles.css';
@import 'colors';
@import 'grid/grid';
@import 'polaris';
@import 'pages';
@import 'hooks';
@import 'components';
@import 'auth';
@import 'checkbox-tree';
@import 'twcss.scss';

body[data-lock-scrolling] {
  overflow: auto hidden;
}

.Polaris-IndexTable__ScrollBarContainer {
  border-top: 0;
  padding: 0;
  .Polaris-IndexTable__ScrollBar {
    overflow-x: auto;
  }
}
