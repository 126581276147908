.HCC-ImageUploader__Container {
  &.is-multiple {
    > .Polaris-Stack {
      > .Polaris-Stack__Item {
        margin-top: 1.1rem;
        margin-left: 1.1rem;
      }
    }
  }
  &.no-thumbnail {
    .Polaris-Stack__Item {
      width: 100%;
      .Polaris-DropZone {
        width: 100%;
        .HCC-ImageUploader__Thumbnail__ButtonWrapper--noThumbnail {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          .Polaris-Stack {
            .Polaris-Stack__Item {
              &:nth-child(1) {
                .Polaris-Button--plain {
                  .Polaris-Button__Text {
                    text-decoration: underline;
                    font-weight: 500;
                    font-size: 16px;
                  }

                  &:nth-child(2) {
                    color: black !important;
                  }
                }
                display: flex;
                justify-content: space-evenly;
              }
              &:nth-child(2) {
                font-weight: 400 !important;
                display: flex;
                justify-content: center;
              }
            }
          }
        }
      }
    }
  }

  &.is-mockup {
    .Polaris-DropZone__Container {
      height: 100%;
    }
    .HCC-ImageUploader__Thumbnail__ButtonWrapper--haveThumbnail {
      z-index: 999;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      button {
        &:nth-child(1) {
          text-decoration: underline;
        }
        &:nth-child(3) {
          .Polaris-Button__Text {
            color: black !important;
          }
          text-decoration: underline black !important;
        }
      }
    }
  }
  .Polaris-DropZone {
    width: 12rem;
    height: 12rem;
  }

  .Polaris-Thumbnail {
    height: 12rem;
    min-width: 12rem;
    width: auto;
  }
}

.Polaris-IndexTable__TableCell,
.HCC-CampaignForm__VariantCell {
  .HCC-ImageUploader__Container {
    .Polaris-DropZone {
      width: 8rem;
      height: 8rem;
    }
    .Polaris-Thumbnail {
      height: 8rem;
      min-width: 8rem;
    }
  }
}

.HCC-ImageUploader__Thumbnail {
  position: relative;
  .Polaris-Choice {
    position: absolute;
    zoom: 0.8;
    top: 0.1rem;
    left: 0.5rem;
    display: none;
  }
  .Polaris-Link {
    position: absolute;
    zoom: 0.8;
    top: 0.1rem;
    right: 0.5rem;
    display: none;
  }
  &:hover {
    .Polaris-Link {
      display: inline-flex;
    }
    .Polaris-Choice {
      display: inline-flex;
    }
  }
}

.HCC-ImageUploader__List {
  .HCC-ImageUploader__PickItem {
    //position: relative;
    cursor: pointer;
    .Polaris-Thumbnail {
      height: 12rem;
      min-width: 12rem;
      width: auto;
    }
    //.Polaris-Link {
    //  position: absolute;
    //  zoom: 0.8;
    //  top: 1rem;
    //  right: 1rem;
    //  display: none;
    //}
    //&:hover {
    //  .Polaris-Link {
    //    display: inline-flex;
    //  }
    //}
  }
}
