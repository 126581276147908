.HCC-ProductList__Body {
  .HCC-AdvancedResourceList__Items {
    .Polaris-ResourceList {
      .Polaris-FormLayout__Items {
        .Polaris-FormLayout__Item {
          min-width: 10rem;

          &:nth-child(1) {
            min-width: 1.5% !important;
          }

          &:nth-child(4),
          &:nth-child(5) {
            min-width: 10rem;
            max-width: 25rem;
          }

          &:nth-last-child(1) {
            min-width: 10rem;
            max-width: 11rem;
          }
          &:nth-last-child(2) {
            min-width: 10rem;
            max-width: 10rem;
          }
        }
      }
    }
  }
}

.HCC-ProductForm__Layout {
  height: 100%;
  padding: 2rem;
  background-color: #fafbfb;
  .Polaris-Choice__Label {
    word-break: break-word;
  }
  .Polaris-Layout {
    height: 100%;

    .Polaris-Layout__Section {
      height: fit-content;
    }
  }

  .HCC-ProductForm__VariantList {
    height: 90%;
    margin-bottom: 1.6rem;
    background-color: #fff;
    border-radius: 0 0 0.8rem 0.8rem;
    box-shadow: var(--p-card-shadow);
    outline: 0.1rem solid transparent;

    &.size-0 {
      height: auto;

      > * {
        padding: 4rem 0 6rem;
        text-align: center;
      }
    }
  }

  .HCC-ProductForm__VariantSelector {
    margin-top: 1.6rem;

    .Polaris-Card {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .Polaris-Button--plain:not(.Polaris-Button.Polaris-Button--disabled) {
    color: #e85d04 !important;
  }
}

.HCC-ProductForm__VariantCell {
  padding: 1rem;
  display: flex;
  align-items: center;
  &:is(.name) {
    .Polaris-TextStyle--variationStrong {
      height: fit-content;
      overflow: auto;
    }
  }
  .HCC-ProductForm__LimitTextLine {
    overflow: hidden;
    display: -webkit-box;
    /* display 2 lines only */
    -webkit-line-clamp: 4;
    line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
  }

  .HCC-ProductForm__VariantCell_SkuField:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  &:is(.sku) {
    span {
      overflow: auto;
      height: fit-content;
      overflow-wrap: break-word;
    }
  }

  &:is(.header) {
    &:is(.regular_price),
    &:is(.sales_price) {
      padding-left: 2rem;
    }
    &:is(.code),
    &:is(.status) {
      padding-left: 3rem;
    }
    border-bottom: 0.1rem solid #e1e3e5;
  }

  &.checkbox {
    padding-left: 1.6rem;
  }

  &.massActions {
    border-bottom: 0.1rem solid #e1e3e5;

    .Polaris-ButtonGroup--fullWidth {
      .Polaris-ButtonGroup__Item {
        flex: 1 0 auto;
      }
    }
  }

  &.body {
    &.sku {
      .Polaris-TextField__Prefix {
        margin-right: 0;
      }
    }

    &.sellerPrice {
      .Polaris-FormLayout__Items {
        align-items: center;

        .Polaris-FormLayout__Item:nth-child(1) {
          min-width: 8.5rem;
          max-width: 8.5rem;
        }
      }
    }

    &.supplierPrice {
      padding-right: 2rem;
    }
  }
}

.HCC-ProductForm__FileList {
  .HCC-AdvancedResourceList__Items {
    .Polaris-ResourceList {
      .Polaris-FormLayout__Items {
        align-items: center;

        .Polaris-FormLayout__Item {
          &:nth-child(1) {
            min-width: 8rem;
            max-width: 8rem;
          }

          &:nth-child(2) {
            max-width: 20rem;
          }

          &:nth-child(3) {
            max-width: 22rem;

            .HCC-ProductForm__FileList__ConditionWrapper .Polaris-TextField {
              &:nth-child(1) {
                max-width: 82px;
              }
            }

            .Polaris-TextField {
              max-width: 182px;
            }
          }

          &:nth-child(4) {
            .Polaris-Select {
              width: 110px;
            }

            .HCC-AutoCompleteSelect__TextField {
              width: 290px;

              .Polaris-TextField {
                min-height: 75px;
              }
            }

            min-width: 42rem;
            max-width: fit-content;
          }

          &:nth-last-child(1) {
            min-width: 3rem;
            max-width: 3rem;
          }

          .HCC-ImageUploader__Container {
            .Polaris-DropZone {
              width: 8rem;
              height: 8rem;
            }

            .Polaris-Thumbnail {
              height: 8rem;
              min-width: 8rem;
              //width: auto;
            }
          }
        }
      }
    }
  }
}

.HCC-ProductForm__SupplierList {
  .Polaris-TextField {
    max-width: 13rem;
  }
}

.HCC-ProductForm__ShippingClassList {
  margin: -2rem 0.4rem;

  .HCC-ProductForm__ShippingClassHeader {
    margin: 2.4rem 1.6rem 0;
  }

  .Polaris-IndexTable__Table {
    table-layout: fixed;
  }

  .Polaris-IndexTable__ScrollBarContainer {
    display: none;
  }

  .Polaris-IndexTable__TableRow {
    .Polaris-IndexTable__TableCell {
      &:nth-child(1) {
        padding-right: 0;
      }

      &:not(:nth-child(1)) {
        padding: 0.8rem;
        min-width: 8rem;
        max-width: 11rem;
      }
    }
  }
}

.HCC-ProductForm__ShippingFeeList {
  .Polaris-FormLayout__Items {
    align-items: center;

    .Polaris-FormLayout__Item:nth-child(3) {
      min-width: 5rem;
      max-width: 5rem;
    }
  }

  .Polaris-FormLayout--condensed {
    &:nth-child(2) {
      .Polaris-FormLayout__Item {
        margin-top: 0.6rem;
      }
    }
  }
}

.Polaris-Tabs__Tab--selected {
  .HCC-ProductList__Tabs {
    .HCC-ProductList__Tab--Badge {
      .Polaris-Badge {
        background-color: #e85d04 !important;
      }
    }
  }
}

.HCC-ProductList__Tabs {
  position: relative;

  .HCC-ProductList__Tab--Badge {
    position: absolute;
    top: -2.5rem;
    right: -2.5rem;
    border-radius: 2rem;

    .Polaris-Badge {
      background-color: #828b95;
      color: #fff;
    }
  }
}

li:has(.HCC-ProductList__Tabs.Tab_Hidden) {
  display: none;
}

.HCC_BulkUploadDesign__Content {
  display: flex;

  .HCC_BulkUploadDesign__ContentLeft {
    width: 50%;
    border-right: 0.1rem solid var(--p-divider);
  }

  .HCC_BulkUploadDesign__ContentRight {
    width: 50%;
    padding: 0 0 5px 20px;
  }

  .HCC_BulkUploadDesign__Content--Button {
    text-align: center;

    .Polaris-Button {
      margin-top: 3rem;
    }
  }
}

.HCC-ProductMockup {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  span {
    font-weight: 400;
    font-size: 16px;
    order: 2;
  }
}

//product grid
.HCC-ProductGridList__Body {
  .HCC-AdvancedResourceList__Items {
    .Polaris-ResourceList {
      .Polaris-FormLayout__Items {
        .Polaris-FormLayout__Item {
          &:nth-child(2) {
            min-width: 5rem;
            max-width: 5%;
          }

          &:nth-child(3) {
            min-width: 20rem;
            max-width: 20%;
          }

          &:nth-child(4) {
            min-width: 10rem;
            max-width: 20%;
          }

          &:nth-child(5) {
            min-width: 25rem;
            max-width: 20%;
          }

          &:nth-child(6) {
            min-width: 5rem;
            max-width: 15rem;
          }

          &:nth-child(7) {
            min-width: 5rem;
            max-width: 15rem;
          }

          &:nth-child(8) {
            min-width: 5rem;
            max-width: 15rem;
          }

          &:nth-child(9) {
            min-width: 5rem;
            max-width: 15rem;
          }

          &:nth-child(10) {
            min-width: 5rem;
            max-width: 15rem;
          }

          &:nth-last-child(1) {
            min-width: 5rem;
            max-width: 8rem;
          }
        }
      }
    }
  }
}

//product template
.HCC-ProductFormStep1 {
  //height: 100%;
  padding: 2rem;
  background-color: #fafbfb;

  .Polaris-Layout {
    height: 100%;

    .Polaris-Layout__Section:nth-child(1) {
      min-width: 75%;
    }

    .Polaris-Layout__Section {
      height: 100%;

      .HCC-ProductFormStep1__Result {
        height: 100%;

        .Polaris-Card {
          height: 100%;

          .Polaris-Scrollable {
            padding: 2rem;
            height: calc(100vh - 21rem);
          }
        }
      }
    }
  }

  .HCC-SelectProductTypeForm {
    height: 100%;

    .Polaris-Card {
      height: 100%;
    }

    .HCC-SelectProductTypeForm--Content {
      border-top: 1px solid #dfe3e8;
      display: flex;
      height: 100%;

      .HCC-SelectProductTypeForm--ContentLeft {
        width: 18%;
        border-right: 2px solid #dfe3e8;
        height: calc(100vh - 24rem);
        overflow-x: hidden;

        .Polaris-Listbox {
          padding: 1rem 0 1rem 1rem;
          height: 100%;
          overflow: hidden;

          .Polaris-Listbox-TextOption {
            margin: 0.4rem 0 0;
            border-radius: 8px 0 0 8px;
          }

          .Polaris-Listbox-TextOption--selected:before {
            width: 0;
          }

          .Polaris-Listbox-TextOption--selected:after {
            box-shadow: none;
          }

          .Polaris-Listbox-TextOption--selected {
            background-color: #e85d04;
            color: #fff;
          }
        }
      }

      .HCC-SelectProductTypeForm--ContentRight {
        height: calc(100vh - 24rem);
        padding: 1rem;
        width: 100%;
        cursor: pointer;

        .HCC-SelectProductTypeForm-Cell.selected {
          .Polaris-Card {
            //border: 2px solid #e85d04;
            box-shadow: #e85d04 0px 0px 0px 2px, #e85d04 0px 4px 6px -1px,
              rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
          }
        }
        .HCC-SelectProductTypeForm-Cell {
          .Polaris-Card {
            box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
            .HCC_SelectProductTypeForm_Image {
              display: grid;
              justify-content: center;
              height: calc(100vw - 87.6vw);
              overflow: hidden;
              img {
                border: 0;
                border-radius: 10px;
                max-height: 24rem;
                width: 100%;
                max-width: 24rem;
              }
            }

            .HCC-SelectProductTypeForm__ProductBody {
              padding: 0.5rem 1rem 0 1rem;
              display: grid;

              .Polaris-Stack__Item {
                padding-bottom: 0.2rem;
                .Polaris-TextStyle--variationStrong {
                  display: -webkit-box;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }
          }
        }
        .HCC-SelectProductTypeForm-Cell.disabled {
          cursor: not-allowed;
          filter: opacity(0.2);
        }
      }
    }
  }
}

.HCC-ProductFormStep2 {
  padding: 2rem;

  .HCC-ProductForm--Variants {
    .HCC-ProductForm--Variants__List {
      .HCC-ImageUploader__Container {
        .Polaris-DropZone {
          width: 8rem !important;
          height: 8rem;
        }

        .Polaris-Thumbnail {
          height: 8rem;
          min-width: 8rem;
          //width: auto;
        }

        .Polaris-InlineError {
          width: 200px;
        }
      }
    }
  }

  .Polaris-Card__Section:has(.HCC-ProductForm--Variants) {
    padding: 0;

    .Polaris-Card__Subsection {
      padding: 1rem 0 1rem 2rem;
      border-bottom: 0.1rem solid #e1e3e5;
    }
  }

  .HCC-ImageUploader__Container {
    .Polaris-Button--plain:not(.Polaris-Button.Polaris-Button--disabled) {
      color: #e85d04 !important;
    }
  }

  .HCC-DragDropList__Container {
    .Polaris-Stack.Polaris-Stack--alignmentCenter {
      width: 100%;
      flex-wrap: nowrap;

      .Polaris-Stack__Item:has(.Polaris-Connected) {
        width: 100%;
        max-width: 90%;
      }

      @media (max-width: 1515px) {
        .Polaris-Stack__Item:has(.Polaris-Connected) {
          width: 100%;
          max-width: 80%;
        }
      }

      @media (max-width: 1125px) {
        .Polaris-Stack__Item:has(.Polaris-Connected) {
          width: 100%;
          max-width: 76%;
        }
      }
    }
  }
}

.HCC-ProductForm--Variants__List.size-0 > * {
  padding: 4rem 0 6rem;
  text-align: center;
}

.HCC-ManageVariantsForm.size-0 > * {
  padding: 2rem 0 2rem;
  text-align: center;
}

.HCC-ManageVariantsForm__TextHeader {
  font-size: 1.4rem;
  padding-top: 0.3rem;
}

.HCC-ManageVariantsForm {
  width: 100%;

  .HCC-ProductForm__VariantCell {
    &:is(.variant_sku),
    &:is(.variant_name),
    &:is(.product_type) {
      span {
        height: fit-content;
        overflow-wrap: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.Polaris-Modal__BodyWrapper:has(.HCC-ManageVariantsForm) {
  .Polaris-Scrollable--hasBottomShadow {
    box-shadow: none;
  }

  .Polaris-Scrollable--verticalHasScrolling {
    overflow-y: hidden;
  }

  .Polaris-Modal-Section {
    padding: 0;
  }
}

.Polaris-Modal__BodyWrapper {
  .Polaris-Tabs__Panel#image-url-panel {
    .Polaris-Modal-Section {
      padding: 4rem 2rem 4rem 2rem;
    }
  }
}

.Polaris-Modal-Dialog__Modal:has(.HCC-ManageVariantsForm) .Polaris-Modal-Header {
  border-bottom: none;
}

.HCC-ManageVariantsForm--header {
  padding: 0 0 1rem 2rem;
  border-bottom: 0.1rem solid #e1e3e5;
}

.HCC-ProductFormVariants--MassEditVariant {
  .HCC-ImageUploader__Container.no-thumbnail .Polaris-Stack__Item .Polaris-DropZone {
    width: 12rem;
    height: 12rem;
  }
}

.HCC-ProductTemplateList__Body {
  .HCC-AdvancedResourceList__Items {
    .Polaris-ResourceList {
      .Polaris-FormLayout__Items {
        .Polaris-FormLayout__Item {
          &:nth-child(2) {
            min-width: 5rem;
            max-width: 4%;
          }

          &:nth-child(4) {
            min-width: 15rem;
            max-width: 40%;
          }

          &:nth-child(5) {
            min-width: 5rem;
            max-width: 18rem;
          }

          &:nth-child(6) {
            min-width: 5rem;
            max-width: 18rem;
          }

          &:nth-child(7) {
            min-width: 5rem;
            max-width: 16rem;
          }

          &:nth-child(8) {
            min-width: 5rem;
            max-width: 15rem;
          }

          &:nth-last-child(1) {
            min-width: 5rem;
            max-width: 8rem;
          }
        }
      }
    }
  }
}

.HCC-ProductFormFile__FileList {
  .Polaris-ResourceItem__ListItem:nth-child(1) {
    .Polaris-ResourceItem__Container {
      padding-bottom: 0;
      border-bottom: 0.1rem solid var(--p-divider);
    }
  }

  .Polaris-ResourceItem__ListItem {
    border-top: none;
  }

  .Polaris-ResourceItem__ItemWrapper {
    overflow: unset;

    .Polaris-ResourceItem__Container {
      padding-bottom: 3rem;
    }

    .HCC-ImageUploader__Container {
      width: 8rem;
      height: 8rem;

      .Polaris-DropZone {
        width: 8rem !important;
        height: 8rem;
      }

      .Polaris-Thumbnail {
        height: 8rem;
        min-width: 8rem;
        //width: auto;
      }

      .Polaris-InlineError {
        width: 200px;
      }
    }
  }
}

.HCC-MediaLibrary_Wrapper {
  .Polaris-DropZone__Container {
    .Polaris-Stack__Item {
      .Polaris-DropZone-FileUpload__Action {
        background: none;

        .Polaris-Stack__Item {
          margin-top: 1rem;

          .Polaris-Icon {
            width: 4rem;
            height: 4rem;
          }
        }
      }
    }
  }
}

// Product Template Bulk Upload
.HCC-BulkUploadProductStep1 {
  .Polaris-DropZone--sizeLarge {
    min-height: 10rem;
    .HCC-BulkUploadProducts__FileError {
      color: red;

      .Polaris-Thumbnail {
        border: 0.1rem solid red;

        * {
          color: red;
        }
      }
    }
  }
  .Polaris-DropZone-FileUpload {
    padding: 2rem;
    .Polaris-DropZone-FileUpload__Action {
      background-color: transparent;

      .Polaris-Stack__Item {
        &:nth-child(1) {
          width: 4rem;
          height: 4rem;
          .Polaris-Icon {
            width: 100%;
            height: 100%;
          }
        }
        &:nth-child(2) {
          button {
            .Polaris-Button__Text {
              font-weight: 600;
              text-decoration: underline;
            }
          }
          span {
            font-size: 1.4rem;
            color: rgb(32, 34, 35);
            font-weight: 400;
          }
        }
      }
    }
  }
  .HCC-BulkUploadProducts__DownloadSample {
    .Polaris-Button__Text {
      color: #e85d04;
    }
  }
}

.HCC-BulkUploadProduct {
  padding: 2rem;

  .HCC-FileUploader__Form {
    &:nth-child(2) {
      margin-top: 3rem;
    }
    &:nth-child(3) {
      margin-top: 3rem;
    }

    .Polaris-DropZone {
      .Polaris-DropZone__Container {
        .Polaris-DropZone-FileUpload {
          padding: 1rem;

          .Polaris-DropZone-FileUpload__Action {
            background: none;

            .Polaris-Stack__Item {
              &:nth-child(1) {
                width: 4rem;
                height: 4rem;

                .Polaris-Icon {
                  width: 100%;
                  height: 100%;
                }
              }

              &:nth-child(2) {
                button {
                  .Polaris-Button__Text {
                    font-weight: 600;
                    text-decoration: underline;
                  }
                }

                span {
                  font-size: 1.4rem;
                  color: rgb(32, 34, 35);
                  font-weight: 400;
                }
              }
            }
          }
        }
      }
    }

    .HCC-FileUploader__FileList {
      border: 0.1rem solid #e1e3e5;
      border-radius: var(--p-border-radius-base);

      .HCC-FileUploader__FileError {
        color: red;

        .Polaris-Thumbnail {
          border: 0.1rem solid red;

          * {
            color: red;
          }
        }
      }

      .HCC-FileUploader__FileSuccess {
        color: #28a745 !important;

        .Polaris-Thumbnail {
          border: 0.1rem solid #28a745 !important;

          * {
            color: #28a745 !important;
          }
        }
      }

      .HCC-FileUploader__File {
        .Polaris-Stack.Polaris-Stack--alignmentCenter {
          display: flex;
          flex-wrap: inherit;
          //width: 80%;
        }
      }
    }
  }
}

.HCC-BulkUploadProductResult__ProductList {
  .HCC-AdvancedResourceList__Items {
    .Polaris-ResourceList {
      .Polaris-FormLayout__Items {
        .Polaris-FormLayout__Item {
          &:nth-child(2) {
            min-width: 5rem;
            max-width: 4%;
          }
          &:nth-child(3) {
            min-width: 20rem;
            max-width: 50%;
          }
          &:nth-child(4) {
            min-width: 10rem;
            max-width: 40%;
          }

          &:nth-child(5) {
            min-width: 5rem;
            max-width: 18rem;
          }

          &:nth-child(6) {
            min-width: 5rem;
            max-width: 18rem;
          }

          &:nth-last-child(1) {
            min-width: 5rem;
            max-width: 8rem;
          }
        }
      }
    }
  }
}
.HCC-ProductTypeForm_RightSide__FieldLabel {
  width: 90px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.HCC-ProductTypeForm_RightSide__SellerPriceField {
  width: 100%;
  display: flex;
  .HCC-ProductTypeForm_RightSide__SellerPriceField_TextField {
    max-width: 155px;
    margin-top: 10px;
  }
}
.HCC-ProductTypeForm_RightSide_ProductCostField {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  .HCC-ProductTypeForm_RightSide_ProductCostField_Label {
    width: 32%;
  }
  .HCC-ProductTypeForm_RightSide_ProductCostField_Input {
    width: 68%;
  }
}
