.Mageplaza__Loading--spinner {
  width: 20px;
  height: 20px;
  border: 2px solid black;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: block;
  animation: rotation 1s linear infinite;
}

.Mageplaza__Loading--animloader {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: block;
  margin: 15px auto;
  position: relative;
  color: #000000;
  box-sizing: border-box;
  animation: animloader .5s linear infinite alternate;
}

@keyframes animloader {
  0% {
    box-shadow: -38px -12px, -14px 0, 14px 0, 38px 0;
  }

  33% {
    box-shadow: -38px 0px, -14px -12px, 14px 0, 38px 0;
  }

  66% {
    box-shadow: -38px 0px, -14px 0, 14px -12px, 38px 0;
  }

  100% {
    box-shadow: -38px 0, -14px 0, 14px 0, 38px -12px;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
