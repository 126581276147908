@import 'pages/home';
@import 'pages/user';
@import 'pages/userRole';
@import 'pages/notification';
@import 'pages/product';
@import 'pages/campaign';
@import 'pages/billing';
@import 'pages/store';
@import 'pages/otherFee';
@import 'pages/order';
@import 'pages/scrollbar';
@import 'pages/setting';
@import 'pages/category';
@import 'pages/importExportHistory';

.HCC-ApiLog__ViewForm {
  .Polaris-TextStyle--variationCode {
    white-space: pre-wrap;
    font-size: 1.3rem;
    cursor: text;
    padding: 1rem 1.6rem;
    margin-right: 2rem;
    min-width: 100%;

    &:after {
      display: none;
    }
  }
}

.Polaris-DataTable__Cell:has(.HCC-Grid__ChildItems) {
  padding: 0;
}

.Polaris-DataTable__Cell--header {
  font-weight: 500;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
